import Vue from "vue";
import SubscriptionPeriod from "@/enums/SubscriptionPeriod";
import {DataFormatter, DateFormatter} from "@/api/helpers";
import SubscriptionPlansConst from "@/api/helpers/consts/SubscriptionPlansConst";
import moment from "moment";

const enterpriseConst = "custom";

const PriceHeader = Object.freeze({
    clear: "payment-page.clear",
    no_free_access_period_no_discount: "payment-page.no_free_access_period_no_discount",
    free_access_period_no_discount: "payment-page.free_access_period_no_discount",
    no_free_access_period_with_discount_permanent: "payment-page.no_free_access_period_with_discount_permanent",
    no_free_access_period_with_discount_once: "payment-page.no_free_access_period_with_discount_once",
    free_access_period_with_discount_permanent: "payment-page.free_access_period_with_discount_permanent",
    free_access_period_with_discount_once: "payment-page.free_access_period_with_discount_once",
    free_access_without_period: "payment-page.free_access_without_period",
    discount_only: "payment-page.discount_only",
});

class SubscriptionHelper extends Vue {
    createEnterprise(actualSubscriptions) {
        if (actualSubscriptions && actualSubscriptions.length > 0) {
            const subscription = actualSubscriptions[actualSubscriptions.length - 1];
            const enterprise = {
                privilegeName: SubscriptionPlansConst.plans.enterprise,
                description: "Customize a plan to meet your needs with large note volumes",
                descriptionEnterprise: "Our sales consultants can provide additional customization options and much larger note volumes.",
                privilegeId: null,
                bestValue: false,
                color: "yellow",
                privilegeChildObjects: subscription.privilegeChildObjects.length !== 0 ? subscription.privilegeChildObjects : []
            };
            actualSubscriptions.push(enterprise);
        }
        return actualSubscriptions;
    }

    date(paymentRespite) {
        var dt = new Date();
        if (paymentRespite && paymentRespite > 0) {
            dt.setDate(dt.getDate() + paymentRespite);
            return DateFormatter.UTCToLongString(dt);
        } else {
            return "today";
        }
    }

    datePlus(paymentRespite, period) {
        var dt = new Date();
        if (paymentRespite && paymentRespite > 0) {
            dt.setDate(dt.getDate() + paymentRespite);
            if (period && period.value === SubscriptionPeriod.monthly.value) {
                dt = new Date(dt.setMonth(dt.getMonth() + 1));
            } else {
                dt = new Date(dt.setYear(1900 + dt.getYear() + 1));
            }
            return DateFormatter.UTCToLongString(dt);
        } else {
            return "today";
        }
    }

    gropPrice(subscription) {
        return !subscription.group ? 0 : subscription.group[subscription.period.priceKeySubscription];
    }

    header(subscription, promo, paymentRespite) {
        var status = PriceHeader.clear;
        if (subscription && subscription.group) {
            let price = this.gropPrice(subscription);
            if (subscription.group.subscriptionAsOneTimeCharge == 'Y') {
                if (promo && promo.discountSum) {
                    status = PriceHeader.discount_only;
                }
            } else {
                if (promo) {
                    if (paymentRespite > 0) {
                        if (promo.discountPeriodDurations == 1) {
                            if (paymentRespite <= 1) {
                                status = PriceHeader.no_free_access_period_with_discount_once;
                            } else {
                                status = PriceHeader.free_access_period_with_discount_once;
                            }
                        } else {
                            if (paymentRespite <= 1) {
                                status = PriceHeader.no_free_access_period_with_discount_permanent;
                            } else {
                                status = PriceHeader.free_access_period_with_discount_permanent;
                            }
                        }
                    } else {
                        if (promo.discountPeriodDurations == 1) {
                            status = PriceHeader.no_free_access_period_with_discount_once;
                        } else {
                            status = PriceHeader.no_free_access_period_with_discount_permanent;
                        }
                    }
                } else {
                    if (paymentRespite > 0) {
                        status = PriceHeader.free_access_period_no_discount;
                    } else {
                        status = PriceHeader.no_free_access_period_no_discount;
                    }
                }
                if (price == 0) {
                    status = PriceHeader.clear;
                }
            }
        } else {
            status = PriceHeader.clear;
        }
        let amount  = subscription && subscription.addons && subscription.addons.length > 0 ?
            this.calcAmount(promo, subscription.addons, subscription.period, subscription.group) : 0;
        return {
            discount: DataFormatter.currency(promo ? promo.discountSum : 0),
            price: DataFormatter.currency(subscription && subscription.group ? subscription.group[subscription.period.priceKeySubscription] : 0),
            days: paymentRespite,
            date: this.date(paymentRespite),
            period: subscription ? subscription.period.forPayment : SubscriptionPeriod.annually.forPayment,
            discount_price: DataFormatter.currency(promo ? promo.subscriptionWithDiscount : 0),
            date_plus_period: this.datePlus(paymentRespite,subscription ? subscription.period : null),
            status: status,
            amount: DataFormatter.currency(amount),
            subscriptionExpDate: (subscription && subscription.group && subscription.group.subscriptionAsOneTimeCharge == 'Y' && subscription.group.subscriptionValidityPeriodMonths) ?
                moment(new Date()).add(subscription.group.subscriptionValidityPeriodMonths, 'M').format("MM/DD/YYYY") : ""
        };
    }

    calcAmount(promo, addons, period, subscription) {
        let chargeAmount = (promo ? promo.chargeTotalWithDiscount : this.addonsPrice(addons, period));
        if (subscription && subscription.subscriptionAsOneTimeCharge == 'Y') {
            let subscriptionChargeAmount = (promo ? promo.subscriptionWithDiscount : subscription[period.priceKeySubscription]);
            chargeAmount += subscriptionChargeAmount;
        }
        return chargeAmount;
    }

    addonsPrice(addons, period) {
        var res = 0;
        addons.forEach((item) => {
            res += item.qty * item[period.priceKeySubscription];
        });
        return res;
    }

    replace(text, data) {
        if (data) {
            Object.keys(data).forEach((key) => {
                let keyStr = `{${key}}`;
                text = text.replaceAll(keyStr, data[key]);
            });
            return text;
        }
        return "";
    }

    groups(saved) {
        var summ = [];
        if (saved && saved.group) {
            summ.push(saved.group.privilegeId);
        }
        if (saved && saved.addons) {
            saved.addons.forEach((item) => {
                for (var i = 0; i < item.qty; i++) {
                    summ.push(item.privilegeId);
                }
            });
        }
        return summ;
    }

    names(privs) {
        if (!privs || !Array.isArray(privs)) {
            return [];
        }
        let res = privs.map((priv) => priv.privilegeName);
        return res;
    }

    standart(saved) {
        const noSubscription = "To begin, please select a subscription";
        const noAddons = ". You can also buy additional notes by choosing one of the three add-on offers below.";
        const preAddons = " and the following one-time purchases:";
        var res = "";
        if (saved && saved.group) {
            const standart = `You have selected a subscription to ${
                saved.group.privilegeName
            } plan for \$${saved.group[saved.period.priceKeySubscription]} per ${
                saved.period.forPayment
            }`;
            res = res + standart;
        } else {
            res = res + noSubscription;
        }
        if (saved && saved.group && saved.addons && saved.addons.length > 0) {
            if (saved.addons.length == 1) {
                const one = ` and a one-time purchase of ${
                    saved.addons[0].privilegeName
                } for \$${saved.addons[0][saved.period.priceKeySubscription]}`;
                res = res + one;
            } else {
                res = res + preAddons;
                saved.addons.forEach((item, index) => {
                    if (index == 0) {
                        const first = ` ${item.privilegeName} for \$${
                            item[saved.period.priceKeySubscription]
                        }`;
                        res = res + first;
                    } else {
                        const other = ` and ${item.privilegeName} for \$${
                            item[saved.period.priceKeySubscription]
                        }`;
                        res = res + other;
                    }
                });
            }
            res = res + ".";
        } else {
            res = res + noAddons;
        }
        return res;
    }

    descriptionOther(saved, subscriptionName) {
        const noAddons = `Your current subscription: ${subscriptionName}. You can select another subscription or buy additional notes by selecting an add-on from the list below.`;
        var res = "";
        if (saved && saved.addons && saved.addons.length > 0) {
            saved.addons.forEach((item, index) => {
                if (index == 0) {
                    const oneAddOn = `You have selected a one-time purchase of ${
                        item.privilegeName
                    } for \$${item[saved.period.priceKeySubscription]}`;
                    res = res + oneAddOn;
                } else {
                    const other = ` and ${item.privilegeName} for \$${
                        item[saved.period.priceKeySubscription]
                    }`;
                    res = res + other;
                }
            });
            res = res + ".";
        } else {
            res = res + noAddons;
        }
        return res;
    }

    descriptionPersonal(data, isSubscribed, subscriptionName) {
        if (!!subscriptionName && data && data.group === null && data.addons.length === 0) {
            return this.descriptionOther({}, subscriptionName);
        }
        if (isSubscribed && data && data.group === null && data.addons.length !== 0) {
            return this.descriptionOther(data, subscriptionName);
        } else {
            return this.standart(data);
        }
    }

    hasData(privileges, id) {
        let res = null;
        for (let i = 0; i < privileges.length; ++i) {
            let item = privileges[i];
            if (item.privilegeId === id) {
                return item.privilegeValue === null ? -1 : item.privilegeValue;
            }
        }
        return res;
    }

    arrayUnique(array1, array2) {
        var a = array1.concat(array2);
        for (var i = 0; i < a.length; ++i) {
            if (!a[i]) {
                a.splice(i--, 1);
            }
        }
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i].privilegeId === a[j].privilegeId) {
                    a.splice(j--, 1);
                }
            }
        }
        return a;
    }

    getSpecialPlan(privilegeName) {
        if (!privilegeName) return null;
        const plan = privilegeName.toLowerCase();
        if (plan.search(SubscriptionPlansConst.plans.enterprise) !== -1) {
            return SubscriptionPlansConst.plans.enterprise;
        } else if (plan.search(SubscriptionPlansConst.plans.unlimited) !== -1) {
            return SubscriptionPlansConst.plans.unlimited;
        } else {
            return null;
        }
    }

    upgrade(plans) {
        if (plans.actualPrivilegesGroups != null) {
            plans.actualPrivilegesGroups.forEach((group) => {
                if (
                    group.privilegeChildObjects == null ||
                    typeof group.privilegeChildObjects.length == "undefined"
                ) {
                    return;
                }
                group.privilegeChildObjects = group.privilegeChildObjects.sort(
                    (a, b) => {
                        return a.privilegeName.localeCompare(b.privilegeName);
                    }
                );
            });
        }
        if (plans.actualPrivileges.length === 0) return;
        plans.actualPrivileges.forEach((privilege, index) => {
            privilege.subscriptions = [];
            if (
                plans.actualPrivilegesGroups == null ||
                typeof plans.actualPrivilegesGroups !== "object"
            ) {
                return;
            }
            plans.actualPrivilegesGroups.forEach((group) => {
                const data = this.hasData(
                    group.privilegeChildObjects,
                    privilege.privilegeId
                );
                const specialPlan = this.getSpecialPlan(group.privilegeName);
                privilege.subscriptions.push(
                    {
                        subscriptionId: group.privilegeId,
                        privilegeValue: this.setDataUsingFake(
                            data,
                            group.privilegeId,
                            index,
                            specialPlan,
                            privilege.hasValue === "Y"
                        ),
                        hasData: this.hasDataUsingFake(data, group.privilegeId),
                        bestValue: group.bestValue,
                    }
                );
            });
        });
        return plans;
    }

    hasDataUsingFake(data, id) {
        if (id == null) {
            return true;
        }
        return data != null;
    }

    setDataUsingFake(data, id, index, specialPlan, hasValue) {
        if (specialPlan === SubscriptionPlansConst.plans.enterprise && hasValue && data !== null) {
            return enterpriseConst;
        } else if (hasValue && (data === -1 || data === null)) {
            return SubscriptionPlansConst.plans.unlimited;
        }
        return data === -1 || data === null ? null : ` up to ${data} items `;
    }

    fullPrice(value, period, isAlacarte) {
        switch (period) {
            case SubscriptionPeriod.monthly:
                return "";
            default:
                if (value[period.priceKeySubscription] > 0) {
                    return (
                        "$" +
                        value[period.priceKeySubscription] +
                        (isAlacarte ? "+ " : " ") +
                        period.value
                    );
                }
                return "";
        }
    }

    originalPrice(value, period) {
        if (value[period.priceKeySubscription] > 0) {
            return (
                value[period.priceKeySubscription]
            );
        }
        return null;
    }

    check(privileges, priv) {
        privileges.forEach((item) => {
            if (item.privilegeId == priv.privilegeId) {
                return item.privilegeValue == null ? "+" : item.privilegeValue;
            }
        });
        return "-";
    }

    perMonthPrice(value, period, isAlacarte) {
        let res = (value[period.priceKeySubscription] / period.div).toFixed(0);
        return res + (isAlacarte ? "+" : "");
    }

    checkForFreePlan(subscription) {
        return !subscription.priceAnnually && !subscription.priceMonthly;
    }
}

export default new SubscriptionHelper();
