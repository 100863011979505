import ApiOffers, {defaultParams} from "@/api/methods/ApiOffers";
import {UserDataHelper} from "@/api/helpers";
import {PRIVILEGES} from "@/layout/Constants";

export default {
    namespaced: true,
    state: {
        allNotes: null,
        selectedNote: null,
        offersList: [],
        selectedOfferType: defaultParams.offerStatus,
        contingencyPeriodParams: {
            defaultContingencyPeriod: 7,
            minContingencyPeriod: 1,
            maxContingencyPeriod: 30
        },
        offersCount: {
            active: 0,
            inEscrow: 0,
            cancelled: 0,
            completed: 0
        },
        hasContingencyPrivilege: false,
    },
    actions: {
        setDefaults({commit}) {
            commit("setSelectedNote", null);
            commit("setAllNotes", null);
            commit("setOffersList", []);
            commit("setSelectedOfferType", defaultParams.offerStatus);
        },
        async getOffersByTimer({commit, dispatch, state}, params) {
            let user = UserDataHelper.getUser();
            let hasContingencyPrivilege = UserDataHelper.isPrivilegeExists(user, PRIVILEGES.SHOW_CONTINGENCY_IN_OFFER);
            commit("setHasContingencyPrivilege", hasContingencyPrivilege);

            const lastOffersOrderNumbers = state.offersList.reduce((acc, offer) => {
                const isLastOffer = offer.buyer != null;
                if (isLastOffer) {
                    let offersHistoryByOrderNumber = state.offersList.filter((curOffer) => curOffer.orderNumber == offer.orderNumber && curOffer.buyer == null);
                    if (offersHistoryByOrderNumber && offersHistoryByOrderNumber.length > 0 && !acc.includes(offer.orderNumber)) {
                        acc.push(offer.orderNumber);
                    }
                }
                return acc;
            }, []);
            params.needPhoto = false;
            params.needStatusScale = false;
            params.orderNumbersWithAllOffers = lastOffersOrderNumbers;
            if (params.noteId && state.allNotes && state.allNotes.length > 0 && params.noteId == state.allNotes[0].noteId) {
                delete params.noteId;
            }
            commit("setSelectedOfferType", params.offerStatus);
            return await ApiOffers.getOffersForSeller(params)
                .then((response) => {
                    if (!response.data) {
                        return;
                    }

                    const contingencyPeriodParams = {
                        defaultContingencyPeriod: response.data.defaultContingencyPeriod,
                        minContingencyPeriod: response.data.minContingencyPeriod,
                        maxContingencyPeriod: response.data.maxContingencyPeriod,
                        daysForCloseEscrow: response.data.daysForCloseEscrow
                    };
                    commit("setContingencyPeriodParams", contingencyPeriodParams);

                    const offersCount = {
                        active: response.data.activeOffersCount,
                            inEscrow: response.data.escrowOffersCount,
                            cancelled: response.data.canceledOffersCount,
                            completed: response.data.completedOffersCount
                    };
                    commit("setOffersCount", offersCount);

                    if (response.data.notes && response.data.notes.length == 1 &&
                        (!response.data.notes[0].offersList || (response.data.notes[0].offersList && response.data.notes[0].offersList.length == 0))) {
                        commit("setAllNotes", []);
                    } else {
                        commit("setAllNotes", response.data.notes);

                        let newSelectedNoteData;
                        if (params.noteId) {
                            newSelectedNoteData = response.data.notes.find((note) => note.noteId === params.noteId);
                            if (newSelectedNoteData) {
                                newSelectedNoteData.offersList.forEach((offer) => {
                                    if (lastOffersOrderNumbers.indexOf(offer.orderNumber) != -1) {
                                        offer.isLastOffer = true;
                                        offer.lastOffersLoaded = true;
                                    }
                                });
                                commit("setOffersList", newSelectedNoteData.offersList);
                            } else if (response.data.notes && response.data.notes.length > 0) {
                                const firstNote = response.data.notes[0];
                                if (!firstNote) {
                                    return;
                                }
                                dispatch("getSelectedNote", firstNote.noteId)
                                    .then((note) => {
                                        commit("setSelectedNote", note);
                                        note.offersList.forEach((offer) => {
                                            if (lastOffersOrderNumbers.indexOf(offer.orderNumber) != -1) {
                                                offer.isLastOffer = true;
                                                offer.lastOffersLoaded = true;
                                            }
                                        });
                                        commit("setOffersList", firstNote.offersList);
                                    });
                            }
                        } else if (response.data.notes && response.data.notes.length > 0) {
                            const firstNote = response.data.notes[0];
                            if (!firstNote) {
                                return;
                            }
                            dispatch("getSelectedNote", firstNote.noteId)
                                .then((note) => {
                                    commit("setSelectedNote", note);
                                    firstNote.offersList.forEach((offer) => {
                                        if (lastOffersOrderNumbers.indexOf(offer.orderNumber) != -1) {
                                            offer.isLastOffer = true;
                                            offer.lastOffersLoaded = true;
                                        }
                                    });
                                    commit("setOffersList", firstNote.offersList);
                                });
                        }
                    }
                });
        },
        async getOffersByNote({commit, dispatch, state}, params) {
            const requestParams = Object.assign(defaultParams, {noteId: params.noteId, offerStatus: state.selectedOfferType});
            return await ApiOffers.getOffersForSellerByNote(requestParams)
                .then((response) => {
                    if (!response.data) return;
                    dispatch("getSelectedNote", params.noteId)
                        .then((note) => {
                            commit("setSelectedNote", note);
                            commit("setOffersList", response.data);
                        });
                });
        },
        async getOffersHistory({commit, state}, params) {
            const lastOffersOrderNumbers = state.offersList.reduce((acc, offer) => {
                const isLastOffer = offer.buyer != null;
                if (isLastOffer) {
                    let offersHistoryByOrderNumber = state.offersList.filter((curOffer) => curOffer.orderNumber == offer.orderNumber && curOffer.buyer == null);
                    if (offersHistoryByOrderNumber && offersHistoryByOrderNumber.length > 0 && !acc.includes(offer.orderNumber)) {
                        acc.push(offer.orderNumber);
                    }
                }
                return acc;
            }, []);
            return await ApiOffers.getOffersHistoryForSeller(params.offerId)
                .then((response) => {
                    if (!response.data) return;
                    const lastOffers = response.data.map((offer) => {
                        offer.isLastOffer = true;
                        return offer;
                    });
                    const allOffers = state.offersList.reduce((acc, offer) => {
                        acc.push(offer);
                        if (offer.offerId === params.offerId) {
                            lastOffers.forEach((offer) => acc.push(offer));
                        }
                        if (lastOffersOrderNumbers.indexOf(offer.orderNumber) != -1) {
                            offer.lastOffersLoaded = true;
                        }
                        return acc;
                    }, []);
                    commit("setOffersList", allOffers);
                });
        },
        getSelectedNote({state}, noteId) {
            const selectedNote = {...state.allNotes.find((note) => note.noteId === noteId)};
            delete selectedNote.offersList;
            return selectedNote;
        }
    },
    mutations: {
        setAllNotes(state, allNotes) {
            state.allNotes = allNotes;
        },
        setSelectedNote(state, note) {
            state.selectedNote = note;
        },
        setOffersList(state, list) {
            if (list == null) {
                state.offersList = [];
            } else {
                state.offersList = list;
            }
        },
        setSelectedOfferType(state, offerType) {
            state.selectedOfferType = offerType;
        },
        setContingencyPeriodParams(state, contingencyPeriodParams) {
            state.contingencyPeriodParams = contingencyPeriodParams;
        },
        setOffersCount(state, offersCount) {
            state.offersCount = offersCount;
        },
        setHasContingencyPrivilege(state, hasContingencyPrivilege) {
            state.hasContingencyPrivilege = hasContingencyPrivilege;
        },
    }
};